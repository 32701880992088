// Head over to the Credentials section in your app on CometChat Dashboard and where you can find your unique App ID, Region and Auth Key. 😊

export const AppConstants = {
  APP_ID: "253640f82084b114",
  REGION: "us",
  AUTH_KEY: "01b3bc5c9041a4168b36686f663331331587861f",
};


// export const AppConstants = {
//   APP_ID: process.env.APP_ID,
//   REGION: process.env.REGION,
//   AUTH_KEY: process.env.AUTH_KEY,
// };